<template>
  <section class="pc:px-[10%] mobile:px-4 bg-osn-yellow-00 flex flex-col justify-center">
    <h2 class="py-8 text-[28px] font-bold text-center">{{ $t('article.space_article')  }}</h2>
    <ul class="flex justify-center flex-wrap mobile:flex-col">
      <li v-for="article in blogArticle"
          class="mx-[10px] mb-10 pc:w-[28%] pc:max-w-[360px] mobile:border-b-2">
        <NuxtLink :to="'/blog/'+article.id">
          <div class="flex justify-center">
            <img class="h-[200px] object-cover aspect-[360/200]"
                 :src="picUrl+article.photo_url"
                 alt="">
          </div>
          <div>
            <h3 class="text-[20px] my-5 font-bold text-center">{{ article.subject }}</h3>
            <p class="mb-5 text-[18px] text-center">{{ article.data3 }}</p>
          </div>
        </NuxtLink>
      </li>
    </ul>
    <NuxtLink to="/blog"
              class="mx-auto my-[40px] py-[10px] px-[30px] h-[50px] text-[18px] font-medium cursor-pointer rounded-[25px]
    border border-osn-black-00 transition-shadow">
      {{ $t('article.go_read_more') }}
    </NuxtLink>
  </section>
</template>
<script setup
        lang="ts">
import {getBlogArticle} from "@/api/banner";

const picUrl = import.meta.env.VITE_APP_BASE_IMG_URL

const {data: blogArticleData} = await useAsyncData(
    'blogArticleData',
    () => getBlogArticle(4));

const blogArticle = computed(() => {
  return blogArticleData.value ? blogArticleData.value.list : [];
});


blogArticleData.value.list.forEach((article) => {
  article.data3 = '裡這發以廣所其出是社不業文，民分度義然發一家著愛；' +
      '子我題高她的。便區紙為……問無處……身時五來故是們個否平文門清深式金在和戰說國未然的說源' +
      '傳且成富獲行：手是見說覺是因高形格風說歷黨、果作看市希一世一何裡、的合談場盡二性臺器教盡'
});
console.log(blogArticle.value);

</script>
<style scoped
       lang="scss">

</style>
